import React, { Component } from "react"
import Helmet from 'react-helmet'; 

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container } from "react-bootstrap";

// Individual components that make up the page
import Newsletter from "../../components/newsletter/newsletter";
import Hero from "../../components/common/Hero"; 
import SEO from "../../components/common/SEO";
import { SectionHeader } from "../../components/common/section-header";   
import Section from "../../components/common/Section"; 

class Contact extends Component {
  render() {
      return (
        <Layout>
          <Helmet bodyAttributes={{ class: "contact-page" }} />
          <SEO />
          <Hero title="Contact Us" color="blue"></Hero>
          <Container style={{ padding: '10px'}}>
            <section id="contact_us">
              <Section name="Contact Us" header="contact_us" /> 
              <div class="container">
                 <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/contact/success">
                 <input type="hidden" name="bot-field" />
                 <input type="hidden" name="form-name" value="contact" />
                  <p>
                    <label>Your Name</label>
                    <input type="text" name="name" />
                  </p>
                  <p>
                    <label>Your Email</label>
                    <input type="email" name="email" />
                  </p>
                  <p>
                    <label>Message</label>
                	<textarea name="message"></textarea>
                  </p>
                  <p>
                    <button type="submit" class="simple-btn">Send</button>
                  </p>
                </form>
              </div>
            </section>
          </Container>
          <Newsletter />
        </Layout>
      ); 
  }
}

export default Contact; 
import React from "react"
import PropTypes from "prop-types"
import "./section-header.css" 

class SectionHeader extends React.Component {
  render() {
    return (
      <div className="section-hed">{this.props.name}</div>
    ); 
  }
}

SectionHeader.propTypes = {
  name: PropTypes.string.isRequired
}

export { SectionHeader }; 
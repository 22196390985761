import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ post }) => {
  const data = useStaticQuery(graphql`
    {
      wordpressSiteMetadata {
        name
        description        
      }
    }
  `);

  if(!post) {
    post = {
      imageLink: false
    }; 
  }

  let defaults = data.wordpressSiteMetadata;
  
  defaults.baseUrl = 'https://www.factsarefirst.com'; 

  if (defaults.baseUrl === '' && typeof window !== 'undefined') {
      defaults.baseUrl = window.location.origin;
  }

  const title = post.title || defaults.name;

  let description = defaults.description; 
  if(post.acf && post.acf.seo_description) {
    description = post.acf.seo_description; 
  } else if(post.description) {
    description = post.description; 
  }
  const url = new URL(post.path || '', defaults.baseUrl);
  let image = (post.acf && post.acf.image) ? new URL(post.acf.image.localFile.childImageSharp.fixed.src, defaults.baseUrl) : false;

  if(post.imageLink && !image) {
    image = `${defaults.baseUrl}/assets/${post.imageLink}`; 
  }

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="Facts Are First" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}       
    </Helmet>
  );
};

export default SEO;
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { SectionHeader } from "./section-header"; 
class Section extends React.Component {
  constructor(props) {
    super(props); 
    this.css = this.props.css ? this.props.css : 'container'; 
    this.renderSection = this.renderSection.bind(this); 
  }
  renderSection(content) {
    let header; 
    if(this.props.header) {
      header = (<SectionHeader name={this.props.header} />); 
    }
    return (
        <section id={this.props.name} className="sectionWrapper">  
        {header}         
          <div className={this.css}  dangerouslySetInnerHTML={{ __html: content }} />        
          {this.props.children}      
        </section>
    ); 
  }
  render() {
   return (
    <StaticQuery
      query={graphql`
      {
        allWordpressPage(filter: {acf: {section_name: {ne: null}}}) {
          edges {
            node {
              content
              acf {
                section_name
              }          
            }
          }
        }
      }
      `}
      render={(data) => {
        const result = data.allWordpressPage.edges.find(
          item => item.node.acf.section_name === this.props.name
        ); 
        if(!result) {
          console.error(`Section: ${this.props.name} was not found in graphql query for section, content could not be displayed.`); 
          return (
            <div/>
          ); 
        } else {
          return(this.renderSection(result.node.content))
        }
        
      }}
    />
  );
}  
};
Section.propTypes = {
  name: PropTypes.string.isRequired,
  header: PropTypes.string,
  css: PropTypes.string
}; 
export default Section ; 